import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ['navbarBurger', 'navbarMenu'];
  }

  toggleMenu(event) {
    this.navbarBurgerTarget.classList.toggle('is-active');
    this.navbarMenuTarget.classList.toggle("is-active");
  }

}
