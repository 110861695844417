import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static get targets() {
    return ["notification"];
  }

  closeNotification(event) {
    event.preventDefault();
    this.notificationTarget.parentNode.removeChild(this.notificationTarget);
    this.resetMessage();
  }

  resetMessage() {
    Rails.ajax({
      type: "post",
      url: "notifications/update",
      data: "notification_name=" + this.data.get('name')
    })
  }
}
