import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ['head', 'showMore', 'showLess'];
  }

  toggleHead(event) {
    event.preventDefault();
    this.headTarget.classList.toggle("is-hidden");
    this.showMoreTarget.classList.toggle("is-hidden");
    this.showLessTarget.classList.toggle("is-hidden");
  }
}
