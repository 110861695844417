import library from "./fontaesome_svg_core";

import {
  faAngleRight as falAngleRight,
  faClock as falClock,
} from "@fortawesome/pro-light-svg-icons"

library.add(
  falAngleRight,
  falClock,
)
