import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ['modal'];
  }

  toggleModal(event) {
    event.preventDefault();
    this.modalTarget.classList.toggle("is-active");
  }

  keyPress(event) {
    if (event.code == "Escape") {
      this.modalTargets.forEach(modal => {
        if (modal.classList.contains('is-active')) {
          modal.classList.toggle("is-active");
        }
      });
    }
  }
}
