import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ['studyBeforeAnsweringButton', 'externalLearningResourcesPanel'];
  }

  showHint(event) {
    event.preventDefault();
    this.studyBeforeAnsweringButtonTarget.classList.add("is-hidden");
    this.externalLearningResourcesPanelTarget.classList.remove("is-hidden");
  }

  recordClick(event){
    const presented_item_id = event.target.dataset.presentedItemId;
    Rails.ajax({
      type: "POST",
      url: "/hint-click",
      data: new URLSearchParams({"presented_item_id": presented_item_id}).toString(),
      dataType: "json"
    })
  }
}
