import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="assessment-start-button"
export default class extends Controller {
  static get targets() {
    return ["startButton", "nextActivityButton", "continueButton"];
  }

  startActivity() {
    this.startButtonTarget.setAttribute("disabled", "disabled");
    this.startButtonTarget.click();
  }

  startNextActivity() {
    this.nextActivityButtonTarget.setAttribute("disabled", "disabled");
    this.nextActivityButtonTarget.classList.add("is-loading");
    this.nextActivityButtonTarget.click();
  }

  continue() {
    this.continueButtonTarget.setAttribute("disabled", "disabled");
    this.continueButtonTarget.classList.add("is-loading");
    this.continueButtonTarget.click();
  }

  // Enable the 'enter' + 'control' key to start an activity
  keyPress(event) {
    if (event.code == "Enter" && event.ctrlKey) {
      if (this.hasStartButtonTarget) {
        this.startActivity();
      } else if (this.hasNextActivityButtonTarget) {
        this.startNextActivity();
      } else if (this.hasContinueButtonTarget) {
        this.continue();
      }
    }
  }
}
