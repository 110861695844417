import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["countdownTimer"];
  }

  initialize() {
    this.setTimer();
    this.currentTimeFromServer = new Date(this.data.get("timerCurrentTime")).getTime();
    this.completeAtFromServer = new Date(this.data.get("timerCompleteAt")).getTime();
    this.currentTime = new Date().getTime();
    this.completeAt = this.currentTime + (this.completeAtFromServer - this.currentTimeFromServer);
    this.started = this.data.get("timerStarted");
    this.assessmentComplete = this.data.get("timerAssessmentComplete");
  }

  connect() {
    if (this.assessmentComplete == "true") {
      this.updateTimer();
      this.stopTimer();
    } else if ((this.started == "true")) {
      this.updateTimer();
      this.startTimer();
    }
  }

  disconnect() {
    this.stopTimer();
  }

  setTimer() {
    this.countdownTimerTarget.innerHTML = this.formatTimer(this.data.get("timerTimeLimit"));
  }

  updateTimer() {
    this.currentTime = new Date().getTime();
    if ((this.completeAt - this.currentTime) <= 0) {
      this.stopTimer();
      this.timeOver();
      return
    } else {
      // calculate the remaining minutes and seconds
      let minutes = Math.floor((this.completeAt - this.currentTime) / 1000 / 60);
      let seconds = Math.floor(((this.completeAt - this.currentTime) / 1000) - (minutes * 60));
      // format the remaining minutes and second as strings
      let remainingMinutes = minutes < 10 ? "0" + minutes : minutes;
      let remainingSeconds = seconds < 10 ? "0" + seconds : seconds;
      this.countdownTimerTarget.innerHTML = `${remainingMinutes} : ${remainingSeconds}`;
    }
  }

  startTimer() {
    this.refreshTimerTimer = setInterval(() => {
      this.updateTimer()
    }, 1000)
  }

  stopTimer() {
    if (this.refreshTimerTimer) {
      clearInterval(this.refreshTimerTimer);
    }
  }

  setTimerToZero() {
    this.countdownTimerTarget.innerHTML = "00 : 00";
  }

  formatTimer(seconds) {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = Math.floor(seconds - (minutes * 60));
    minutes = minutes < 10 ? "0" + minutes : minutes;
    remainingSeconds = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
    return `${minutes} : ${remainingSeconds}`;
  }

  timeOver() {
    this.setTimerToZero();
    Rails.ajax({
      url: this.data.get("timeOverPath"),
      type: 'post'
    });
  }
}
