import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  confirm(event) {
    const message = this.message();
    const confirmed = window.confirm(message);

    if (confirmed === false) {
      event.preventDefault();
    }
  }

  message() {
    return "Always use caution when opening files from someone you do not know. Before opening, such as by double-clicking, use antivirus software or a trusted 3rd party service to verify the file's safety.";
  }
}
