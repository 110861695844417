import { Controller } from "@hotwired/stimulus";
import * as d3 from "d3";

export default class extends Controller {

  initialize() {
    this.urlValue = this.element.getAttribute("data-d3-weekly-points-bar-chart-url-value");
  }

  connect() {
    this.loadData();
  }

  async loadData() {
    await d3.json(this.urlValue).then(data => {
      this.drawChart(data);
    })
  }

  drawChart(data, {
    xData = d => d.day, // given d in data, returns the (ordinal) x-value
    yData = d => d.points, // given d in data, returns the (quantitative) y-value
    marginTop = 20, // the top margin, in pixels
    marginRight = 0, // the right margin, in pixels
    marginBottom = 20, // the bottom margin, in pixels
    marginLeft = 0, // the left margin, in pixels
    width = 270, // the outer width of the chart, in pixels
    height = 150, // the outer height of the chart, in pixels
    xDomain, // an array of (ordinal) x-values
    xRange = [0, width], // [left, right]
    yType = d3.scaleLinear, // y-scale type
    yDomain, // [ymin, ymax]
    minBarHeight = 3, // minimum height for each bar
    yRange = [height - marginBottom - minBarHeight, marginTop - minBarHeight], // [bottom, top]
    xPadding = 0.33, // amount of x-range to reserve to separate bars
    color = "#dc4b89" // bar fill color
    } = {}) {

    // -----------------------------------------------------
    // set up data
    // -----------------------------------------------------

    // Compute values.
    const xAxisValues = d3.map(data, xData);
    const yAxisValues = d3.map(data, yData);

    // Compute default domains, and unique the x-domain.
    if (xDomain === undefined) xDomain = xAxisValues;
    if (yDomain === undefined) yDomain = [0, d3.max(yAxisValues) + minBarHeight];
    xDomain = new d3.InternSet(xDomain);

    // Omit any data not present in the x-domain.
    const I = d3.range(xAxisValues.length).filter(i => xDomain.has(xAxisValues[i]));

    // -----------------------------------------------------
    // create the xAxis and x/y scales
    // -----------------------------------------------------

    // Construct scales, axes, and formats.
    const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
    const yScale = yType(yDomain, yRange);

    // -----------------------------------------------------
    // svg
    // -----------------------------------------------------

    const svg = d3.select("#weekly-points-bar-chart")
      .append('svg')
      .attr("viewBox", [0, 0, width, height])

    const chart = svg.append("g")
      .classed('chart', true);

    // -----------------------------------------------------
    // create the bars and labels
    // -----------------------------------------------------

    const bar = chart.selectAll("g")
      .data(I)
      .join("g");

    bar.append("rect")
      .attr("fill", color)
      .attr("x", i => xScale(xAxisValues[i]))
      .attr("y", i => yScale(yAxisValues[i])) // set the top of the bar
      .attr("height", i => height - marginBottom - yScale(yAxisValues[i])) // set the height of the bar
      .attr("width", xScale.bandwidth())
      .attr("rx", 3)
      .attr("ry", 3);

    // add the value labels for each bar
    bar.append("text")
      .attr("fill", "#777777")
      .attr("x", i => xScale(xAxisValues[i]) + xScale.bandwidth() / 2)
      .attr("y", i => yScale(yAxisValues[i]) - 8) // set the label position with padding
      .style("text-anchor", "middle")
      .style("font", "12px 'Roboto medium")
      .attr("dy", "0.35em")
      .text(i => (yAxisValues[i] == 0) ? "" : yAxisValues[i]);

    // add xAxis labels
    bar.append("text")
      .attr("fill", "#777777")
      .attr("x", i => xScale(xAxisValues[i]) + xScale.bandwidth() / 2)
      .style("text-anchor", "middle")
      .style("font", "12px 'Roboto medium")
      .attr("y", height - 10)
      .attr("dy", "0.35em")
      .text(i => ["M", "T", "W", "T", "F", "S", "S"][i]);
  }
}
