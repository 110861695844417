import { Controller } from "@hotwired/stimulus";
import tippy, { followCursor } from "tippy.js";

export default class extends Controller {

  initialize() {
    document.addEventListener(
      "cable-ready:after-morph",
      this.initializeTippys.bind(this),
      true
    );
  }

  connect() {
    this.initializeTippys();
  }

  disconnect() {
    this.destroyTippys();
  }

  initializeTippys() {
    this.destroyTippys();
    tippy(document.querySelectorAll("[data-tippy-content]"), {
      arrow: false,
      followCursor: true,
      placement: 'left-start',
      plugins: [followCursor],
      allowHTML: true,
      touch: false,
    });
  }

  destroyTippys() {
    let tips = document.querySelectorAll("[data-tippy-content]");
    tips.forEach((e) => {
      if (e._tippy) e._tippy.destroy();
    });
  }

  disableTooltip(event) {
    event.target.parentElement.parentElement.parentElement.parentElement._tippy.disable();
  }

  enableTooltip(event) {
    event.target.parentElement.parentElement.parentElement.parentElement._tippy.enable();
  }
}
