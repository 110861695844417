import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static get targets() {
        return ['rangeInput', 'answers', 'firstAnswerPointsValue',
        'secondAnswerPointsValue', 'firstAnswer', 'secondAnswer',
        'firstAnswerPoints', 'secondAnswerPoints', 'meter'];
    }

    get assessmentsController() {
      const e = document.getElementById('assessment');
      return this.application.getControllerForElementAndIdentifier(e, "assessments");
    }

    initialize() {
      this.assessmentType = this.element.getAttribute("data-assessment-type");
      this.totalPoints = parseInt(this.element.getAttribute("data-total-points"));
      this.updateMeter();
    }

    selectAnswerWithSlider(event) {
      const first_answer = document.getElementsByName(`${this.assessmentType}[answer]`)[0];
      const second_answer = document.getElementsByName(`${this.assessmentType}[answer]`)[1];
      if (this.rangeInputTarget.value < 50) {
        first_answer.checked = true;
        this.updateItemPoints("first", this.totalPoints * ((100 - this.rangeInputTarget.value)/100));
        this.assessmentsController.enableSubmit();
        return;
      }
      if (this.rangeInputTarget.value > 50) {
        second_answer.checked = true;
        this.updateItemPoints("second", this.totalPoints * ((this.rangeInputTarget.value)/100));
        this.assessmentsController.enableSubmit();
        return;
      }
      else {
        this.assessmentsController.disableSubmit();
        first_answer.checked = false;
        second_answer.checked = false;
        this.updateItemPoints("", this.totalPoints * 0.5);
        return;
      }
    }

    updateItemPoints(answer, points) {
      if (answer == "first") {
        this.firstAnswerPointsValueTarget.innerHTML = `+${Math.round(points)}`;
        this.secondAnswerPointsValueTarget.innerHTML = `+${Math.round(this.totalPoints - points)}`;
        this.secondAnswerTarget.classList.remove('is-selected');
        this.firstAnswerTarget.classList.add('is-selected');
        return;
      }
      if (answer == "second") {
        this.secondAnswerPointsValueTarget.innerHTML = `+${Math.round(points)}`;
        this.firstAnswerPointsValueTarget.innerHTML = `+${Math.round(this.totalPoints - points)}`;
        this.firstAnswerTarget.classList.remove('is-selected');
        this.secondAnswerTarget.classList.add('is-selected');
        return;
      } else {
        this.firstAnswerPointsValueTarget.innerHTML = "-";
        this.secondAnswerPointsValueTarget.innerHTML = "-";
        this.firstAnswerTarget.classList.remove('is-selected');
        this.secondAnswerTarget.classList.remove('is-selected');
        return;
      }
    }

    highlightPoints() {
      const rangeInputValue = this.rangeInputTarget.value;
      if (rangeInputValue < 50) {
        setTimeout( () => {
          this.firstAnswerPointsTarget.classList.add("highlight-flash-selected-points");
        }, 100);
        setTimeout( () => {
          this.firstAnswerPointsTarget.classList.remove("highlight-flash-selected-points");
        }, 500);
      } if (rangeInputValue > 50) {
        setTimeout( () => {
          this.secondAnswerPointsTarget.classList.add("highlight-flash-selected-points");
        }, 100);
        setTimeout( () => {
          this.secondAnswerPointsTarget.classList.remove("highlight-flash-selected-points");
        }, 500);
      };
    }

    updateMeter(event) {
      if (this.rangeInputTarget.value > 50) {
        this.meterTarget.style.transform = "scaleX(1)";
        let scaleToValue = (this.rangeInputTarget.value - 50)/100;
        let maskWidth = scaleToValue * 514;
        mask.setAttribute("width", maskWidth)
      } else {
        this.meterTarget.style.transform = "scaleX(-1)";
        let scaleToValue = (50 - this.rangeInputTarget.value)/100;
        let maskWidth = scaleToValue * 514;
        mask.setAttribute("width", maskWidth)
      }
    }
}
