import library from "./fontaesome_svg_core";

import {
  faCheckSquare as farCheckSquare,
  faCircle as farCircle,
  faClock as farClock,
  faExternalLink as farExternalLink,
  faGlobe as farGlobe,
  faGripLines as farGripLines,
  faLongArrowAltDown as farLongArrowAltDown,
  faLongArrowAltUp as farLongArrowAltUp,
  faMinus as farMinus,
  faSortAlphaDown as farSortAlphaDown,
  faSortAlphaUp as farSortAlphaUp,
  faSortAlphaUpAlt as farSortAlphaUpAlt,
  faSquare as farSquare,
} from "@fortawesome/pro-regular-svg-icons"

library.add(
  farCheckSquare,
  farCircle,
  farClock,
  farExternalLink,
  farGlobe,
  farGripLines,
  farLongArrowAltDown,
  farLongArrowAltUp,
  farMinus,
  farSortAlphaDown,
  farSortAlphaUp,
  farSortAlphaUpAlt,
  farSquare,
)
