import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["count", "skills", "pagination", "showMore"]

  initialize() {
    this.next_page_url = this.paginationTarget.querySelector("a[rel='next']");
    if (this.next_page_url == null) {
      this.showMoreTarget.setAttribute("hidden", true);
      return
    };
  }

  getMaxPageHeight() {
    var body = document.body,
    html = document.documentElement;
    var page_height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    return page_height
  }

  loadMoreOnScroll() {
    this.next_page_url = this.paginationTarget.querySelector("a[rel='next']");
    if (this.next_page_url == null) {
      this.showMoreTarget.setAttribute("hidden", true);
      return
    };
    if (window.pageYOffset >= this.getMaxPageHeight() - window.innerHeight - 0) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.updatePage();
      }, 1000)
    }
  }

  loadMoreOnClick(event) {
    event.preventDefault();
    this.next_page_url = this.paginationTarget.querySelector("a[rel='next']");
    if (this.next_page_url == null) {
      this.showMoreTarget.setAttribute("hidden", true);
      return
    };
    if (!event.detail || event.detail == 1) {
      this.updatePage();
    }
  }

  updatePage() {
    Rails.ajax({
      type: 'GET',
      url: this.next_page_url.href,
      dataType: 'json',
      success: (data) => {
        this.skillsTarget.insertAdjacentHTML('beforeend', data.skills);
        this.paginationTarget.innerHTML = data.pagination;
        this.countTarget.innerHTML = data.count;
      }
    })
  }
}
