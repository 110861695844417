import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "itemType",
    "programmingLanguage",
    "programmingLanguageField",
    "keyImage",
    "keyImageField",
    "distractorImage",
    "distractorImageField",
    "precisionField",
    "precision",
    "unitField",
    "unit",
  ]

  connect() {
    this.toggle();
  }

  toggle() {
    if (this.itemTypeTarget.value.includes("Coding")) {
      this.showCodingFields();
      this.hideImageFields();
      this.hideNumericFields();
    } else if (this.itemTypeTarget.value.includes("Image")) {
      this.showImageFields();
      this.hideCodingFields();
      this.hideNumericFields();
    } else if (this.itemTypeTarget.value.includes("Numeric")) {
      this.showNumericFields();
      this.hideImageFields();
      this.hideCodingFields();
    } else {
      this.hideCodingFields();
      this.hideImageFields();
      this.hideNumericFields();
    }
  }

  showCodingFields() {
    this.programmingLanguageFieldTarget.hidden = false;
    this.programmingLanguageTarget.required = true;
  }

  hideCodingFields() {
    this.programmingLanguageFieldTarget.hidden = true;
    this.programmingLanguageTarget.required = false;
    this.programmingLanguageTarget.value = null;
  }

  showImageFields() {
    if (this.hasKeyImageTarget && this.hasKeyImageFieldTarget) {
      this.keyImageFieldTarget.hidden = false;
      this.keyImageTarget.required = true;
    }
    if (this.hasDistractorImageTarget && this.hasDistractorImageFieldTarget) {
      this.distractorImageFieldTarget.hidden = false;
      this.distractorImageTarget.required = true;
    }
  }

  hideImageFields() {
    if (this.hasKeyImageTarget && this.hasKeyImageFieldTarget) {
      this.keyImageFieldTarget.hidden = true;
      this.keyImageTarget.required = false;
      this.keyImageTarget.value = null;
    }
    if (this.hasDistractorImageTarget && this.hasDistractorImageFieldTarget) {
      this.distractorImageFieldTarget.hidden = true;
      this.distractorImageTarget.required = false;
      this.distractorImageTarget.value = null;
    }
  }

  showNumericFields() {
    this.precisionFieldTarget.hidden = false;
    this.precisionTarget.required = true;

    this.unitFieldTarget.hidden = false;
    this.unitTarget.required = true;
  }

  hideNumericFields() {
    this.precisionFieldTarget.hidden = true;
    this.precisionTarget.required = false;
    this.precisionTarget.value = null;

    this.unitFieldTarget.hidden = true;
    this.unitTarget.required = false;
    this.unitTarget.value = null;
  }
}
