import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="content-block"
export default class extends Controller {

  static targets = [
    'sectionActions',
  ]

  static values = {
    sectionActionButton: {
      type: String,
      default: ".button",
    },
  }

  connect(event) {
  }

  disableSectionActions(event) {
    let buttons = this.sectionActionsTarget.querySelectorAll(
      this.sectionActionButtonValue
    )

    buttons.forEach((button) => {
      button.classList.add('is-hidden')
    })
  }

  enableSectionActions(event) {
    let buttons = this.sectionActionsTarget.querySelectorAll(
      this.sectionActionButtonValue
    )

    buttons.forEach((button) => {
      button.classList.remove('is-hidden')
    })
  }
}
