import { Controller } from "@hotwired/stimulus"

const UPVOTE = "true";
const DOWNVOTE = "false";
const REMOVE_VOTE = "removeVote";
const INACTIVE_VOTE_BUTTON_CLASS = "has-text-gray"; // Button icon defaults to blue, so set it to gray to "inactivate" it

export default class extends Controller {
  static get targets() {
    return ['upvoteButtonIcon', 'downvoteButtonIcon'];
  }

  connect() {
    this.element.addEventListener("vote", this.onVote.bind(this));
  }

  onVote(event) {
    this.updateVoteButtons(event);
  }

  updateVoteButtons(event) {
    switch (event.detail.message) {
      case REMOVE_VOTE:
        this.deactivateVoteButtons();
        break;
      case UPVOTE:
        this.activateVoteButton(this.upvoteButtonIconTarget);
        break;
      case DOWNVOTE:
        this.activateVoteButton(this.downvoteButtonIconTarget);
        break;
    }
  }

  activateVoteButton(target) {
    this.deactivateVoteButtons();
    target.classList.remove(INACTIVE_VOTE_BUTTON_CLASS);
  }

  deactivateVoteButtons() {
    this.upvoteButtonIconTarget.classList.add(INACTIVE_VOTE_BUTTON_CLASS);
    this.downvoteButtonIconTarget.classList.add(INACTIVE_VOTE_BUTTON_CLASS);
  }
}
