import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "dropdown",
    "button",
    "option"
  ]

  static values = {
    buttonClass: {
      type: String,
      default: ".dropdown-button",
    },
    menuClass: {
      type: String,
      default: ".dropdown-menu",
    },
    optionClass: {
      type: String,
      default: ".dropdown-item",
    }
  }

  connect() {
    this.bindDropdowns()
  }

  bindDropdowns() {
    let dropdowns = document.querySelectorAll(this.buttonClassValue)
    let dropdown = dropdowns[dropdowns.length - 1]
    let menu = dropdown.offsetParent.querySelector(this.menuClassValue)

    menu.classList.add('align-top')
  }

  activeOption(event) {
    event.currentTarget.classList.add("is-active")
  }

  disableOption(event) {
    event.currentTarget.classList.remove("is-active")
  }

  resetOptions() {
    let options = this.dropdownTarget.querySelectorAll(this.optionClassValue)

    options.forEach((option) => {
      if (!option.classList.contains("is-active")) { return }

      option.classList.remove("is-active")
    })
  }

  selectOption(event) {
    this.resetOptions()
    this.activeOption(event)
  }
}
