import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  recordClick(event){
    const resource_id = event.target.dataset.externalLearningResourceId;
    Rails.ajax({
      type: "POST",
      url: "/resource-click",
      data: new URLSearchParams(
          {
            "external_learning_resource_id": resource_id,
          }
        ).toString(),
      dataType: "json"
    })
  }
}
