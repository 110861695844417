import { Controller } from '@hotwired/stimulus';
import Swipe from 'swipejs';

export default class extends Controller {
  static get targets() {
    return ['slider', 'previousSlide', 'nextSlide'];
  }

  initialize() {
    window.helpContent = new Swipe(document.getElementById('slider'), {
      startSlide: 0,
      speed: 400,
      draggable: true,
      continuous: false,
      disableScroll: false,
      stopPropagation: false,
      ignore: ".scroller",
      callback: function(index, elem, dir) {},
      transitionEnd: function(index, elem) {}
    });
  }

  nextSlide(event) {
    window.helpContent.next();
  }

  previousSlide(event) {
    window.helpContent.prev();
  }

}
