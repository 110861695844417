import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="collapsible"
export default class extends Controller {
  static get targets() {
    return ["collapsiblePanel", "collapsibleCaret"];
  }

  toggle(event) {
    event.preventDefault();
    this.collapsibleCaretTarget.classList.toggle("active");
    this.collapsiblePanelTarget.classList.toggle("active");
  }
}
