import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    const anchor = window.location.hash

    if (this.element && anchor) {
      const topOffset = parseInt(this.element.dataset.topOffset, 10) || 0;
      const target = document.getElementById(anchor.slice(1));
      const targetTop = target.getBoundingClientRect().top;

      window.addEventListener("load", () => {
        window.scrollTo(0, targetTop - topOffset);
      });
    }
  }
}
