import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "welcomeMessage",
    "title",
    "ssoTitle",
    "ssoHelpText",
    "passwordSection",
    "ssoButton",
    "returnToSignInLink",
    "navigationLinks",
  ];

  connect() {
    this.ssoFormTargets = [
      this.ssoHelpTextTarget,
      this.returnToSignInLinkTarget,
      this.ssoTitleTarget,
    ];
    this.signInFormTargets = [
      this.passwordSectionTarget,
      this.ssoButtonTarget,
      this.navigationLinksTarget,
      this.welcomeMessageTarget,
      this.titleTarget,
    ];
  }

  switchToSsoForm(event) {
    event.preventDefault();
    this.hideContent(this.signInFormTargets);
    this.showContent(this.ssoFormTargets);
    this.passwordSectionTarget.querySelector("input").required = false;
  }

  switchToSignInForm(event) {
    event.preventDefault();
    this.showContent(this.signInFormTargets);
    this.hideContent(this.ssoFormTargets);
    this.passwordSectionTarget.querySelector("input").required = true;
  }

  hideContent(targets) {
    targets.forEach((target) => {
      target.classList.add("is-hidden");
    });
  }

  showContent(targets) {
    targets.forEach((target) => {
      target.classList.remove("is-hidden");
    });
  }
}
