import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  scroll() {
    const anchorId = this.element.dataset.anchorId;
    const anchorElement = document.getElementById(anchorId);

    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: "instant" });
    }
  }
}
