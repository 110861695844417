import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["uploadFileButton"]

  submitForm() {
    document.getElementById("file-submission-form").submit();
  }

  enableUploadFileButton() {
    this.uploadFileButtonTargets.forEach((button) => {
      button.removeAttribute("disabled");
    });
  }
}
