import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { delay: Number };

  connect() {
    const delay = this.delayValue || 3000;

    this.timeout = setTimeout(() => {
      this.element.remove();
    }, delay);
  }

  disconnect() {
    clearTimeout(this.timeout);
  }
}
