import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.startTime = performance.now();
    window.addEventListener("load", () => this.logPageLoad());
  }

  getAssessmentId() {
    return this.data.get("assessmentId");
  }

  getUrl() {
    return this.data.get("url");
  }

  getToken() {
    return this.data.get("token");
  }

  logPageLoad() {
    const load_time = (performance.now() - this.startTime).toFixed(1)
    this.log("Page loaded", { load_time })
  }

  log(message, options = {}) {
    const data = {
      assessment: {
        message: typeof message === 'string' ? message : JSON.stringify(message),
        path: this.getUrl(),
        assessment_id: this.getAssessmentId(),
        token: this.getToken(),
        ...options
      }
    }
    const token = this.getToken();
    let url = `/assessments/api/v1/assessments/${data.assessment.assessment_id}/browser_events`;
    if (token) {
      url += `?token=${encodeURIComponent(token)}`;
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }).catch(error => console.error('Logging failed:', error));
  }
}
