import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "navbarSearchForm", "navbarSearchButton", "searchForm", "searchButton" ];

  toggleNavbarSearchButton() {
    if (this.navbarSearchFormTarget.value === "") {
      this.navbarSearchButtonTarget.setAttribute("disabled", true);
    } else {
      this.navbarSearchButtonTarget.removeAttribute("disabled");
    }
  }

  toggleSearchButton() {
    if (this.searchFormTarget.value === "") {
      this.searchButtonTarget.setAttribute("disabled", true);
    } else {
      this.searchButtonTarget.removeAttribute("disabled");
    }
  }
}
