import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ['text', 'confirmMessage'];
  }

  copyTextToClipboard(event) {
    event.preventDefault();
    navigator.clipboard.writeText(this.textTarget.textContent.trim())

    if (this.hasConfirmMessageTarget) {
      this.displayConfirmMessage();
    }
  }

  displayConfirmMessage() {
    const message = document.createElement('span');
    message.textContent = '\u2713 copied to clipboard';
    this.confirmMessageTarget.appendChild(message);

    setTimeout(() => {
      message.remove();
    }, 1000);
  }
}
