import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return [
      "addCandidateButton",
      "bulkUploadButton",
      "addCandidateForm",
      "bulkUploadForm",
    ];
  }

  showAddCandidateForm(event) {
    event.preventDefault();
    this.addCandidateButtonTarget.classList.toggle("is-hidden");
    this.bulkUploadButtonTarget.classList.toggle("is-hidden");
    this.addCandidateFormTarget.classList.toggle("is-hidden");
  }

  showBulkUploadForm(event) {
    event.preventDefault();
    this.addCandidateButtonTarget.classList.toggle("is-hidden");
    this.bulkUploadButtonTarget.classList.toggle("is-hidden");
    this.bulkUploadFormTarget.classList.toggle("is-hidden");
  }

  closeAddCandidateForm(event) {
    event.preventDefault();
    this.addCandidateButtonTarget.classList.toggle("is-hidden");
    this.bulkUploadButtonTarget.classList.toggle("is-hidden");
    this.addCandidateFormTarget.classList.toggle("is-hidden");
  }

  closeBulkUploadCandidateForm(event) {
    event.preventDefault();
    this.addCandidateButtonTarget.classList.toggle("is-hidden");
    this.bulkUploadButtonTarget.classList.toggle("is-hidden");
    this.bulkUploadFormTarget.classList.toggle("is-hidden");
  }
}
