import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ['registrationButton'];
  }

  toggleRegistrationButton(event) {
    this.registrationButtonTarget.toggleAttribute("disabled");
  }
}
