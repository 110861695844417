import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["checkboxList", "checkBox"];
  }

  initialize() {
    this.updateCheckboxes();
  }

  updateCheckboxes(event) {
    if (this.checkedCheckboxesCount() > 0) {
      this.removeRequired();
    } else {
      this.requireAll();
    }
  }

  checkedCheckboxesCount() {
    return this.checkboxListTarget.getElementsByClassName("selected").length;
  }

  removeRequired() {
    this.checkBoxTargets.forEach(
      (e) => e.removeAttribute("required"))
  }

  requireAll() {
    this.checkBoxTargets.forEach(
      (e) => e.setAttribute("required", "required"))
  }
}
