import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static get targets() {
    return ["messageHeader"];
  }

  togglePanel() {
    this.messageHeaderTarget.classList.toggle("active");
  }
}
