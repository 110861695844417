import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.assessmentUrl = this.data.get("assessmentUrl");
  }

  connect() {
    this.setBackButtonUrlToAssessmentInstructions();
  }

  setBackButtonUrlToAssessmentInstructions() {
    // override the back button and return the leaner to the instructions page
    history.pushState({turbolinks: true, url: this.assessmentUrl}, document.title, this.assessmentUrl)
  }
}
