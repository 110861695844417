import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "button"];

  connect() {
    this.inputTarget.addEventListener("input", () => {
      this.validateGroupCode();
    });
  }

  validateGroupCode() {
    const groupCode = this.inputTarget.value;
    const continueButton = this.buttonTarget;

    if (groupCode.length === 8) {
      continueButton.removeAttribute("disabled");
    } else {
      continueButton.setAttribute("disabled", "true");
    }
  }
}
