import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String, target: String }

  redirect() {
    window.open(this.urlValue, this.targetValue || "_self");
  }

  redirectOnEnter(event) {
    if (event.code === "Enter") {
      window.open(this.urlValue, this.targetValue || "_self");
    }
  }
}
