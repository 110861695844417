import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['invitationCodeToken', 'email', 'name', 'nextButton'];
  }

  toggleSubmit(event) {
    this.nextButtonTargets.forEach( (e) => e.removeAttribute("disabled"));
  }

  redeemInvitationCode(event){
    const invitationCodeForm = document.getElementById('invitation-code-form');
    Rails.fire(invitationCodeForm, 'submit');
  }

  toggleNextButton() {
    if (this.invitationCodeTokenTarget.value.trim() === "" || this.emailTarget.value.trim() === "" || this.nameTarget.value.trim() === "" ) {
      this.nextButtonTarget.setAttribute("disabled", true);
    } else {
      this.nextButtonTarget.removeAttribute("disabled");
    }
  }
}
