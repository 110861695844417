import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["skillInput"];

  changeDatalist(event) {
    this.skillInputTarget.value = "";
    this.skillInputTarget.setAttribute("list",event.currentTarget.value);
  }
}

