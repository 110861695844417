import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["downvoteSurveyModal"];
  }

  initialize() {
    this.responsesPath = this.data.get("responsesPath");
  }

  get downvoteSurveyModal() {
    return this.application.getControllerForElementAndIdentifier(this.downvoteSurveyModalTarget, "modal");
  }

  get votableController() {
    return this.application.getControllerForElementAndIdentifier(this.downvoteSurveyModalTarget.parentElement.parentElement, "votable");
  }

  displayDownvoteSurvey(event) {
    this.downvoteSurveyModal.toggleModal(event);
  }

  hideDownvoteSurvey(event) {
    this.downvoteSurveyModal.toggleModal(event);
  }

  submit(event) {
    event.preventDefault();
    let downvoteSurveyFormData = new FormData(event.currentTarget.form);
    this.votableController.vote(event).then(vote => {
      // after the vote is cast, write the survey response
      downvoteSurveyFormData.append("vote_id", vote.id);
      this.handleDownvoteSurveyResponseRequest("POST", this.responsesPath, downvoteSurveyFormData);
      // hide the modal
      this.hideDownvoteSurvey(event);
    });
  }

  handleDownvoteSurveyResponseRequest(method, url, data) {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        type: method,
        url: url,
        data: data,
        success: resolve,
        error: reject
      });
    });
  }
}
