import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static get targets() {
    return ["box", "checkBox"];
  }

  toggleBox(event) {
    event.preventDefault();
    if (this.checkBoxTarget.disabled == false) {
      this.checkBoxTarget.checked = !this.checkBoxTarget.checked;
      this.boxTarget.classList.toggle("selected");
    }
  }
}
