import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

const UPVOTE = "true";
const DOWNVOTE = "false";

export default class extends Controller {
  static get targets() {
    return ['upvoteButtonIcon', 'downvoteButtonIcon'];
  }

  initialize() {
    this.itemId = this.data.get("itemId");
    this.lastVote = this.data.get("lastVote");
    this.votesPath = this.data.get("votesPath"); // create a new vote
    this.votePath = this.data.get("votePath"); // destroy a vote
    this.token = this.data.get("token");
    this.dispatchVote(this.lastVote);
  }

  async vote(event) {
    const { dataset } = event.currentTarget;
    const voteType = dataset.voteType;
    const isUpvote = (voteType === "upvote");
    const isDownvote = (voteType === "downvote");
    const isSurveyEnabled = (dataset.downvoteSurveyEnabled === "true");

    try {
      if (isUpvote) {
        await this.processVote(UPVOTE, DOWNVOTE);
      } else if (this.lastVote != DOWNVOTE && isDownvote && isSurveyEnabled) {
        const votableDownvoteSurveyController = this.application.getControllerForElementAndIdentifier(event.currentTarget.parentElement, "votable-downvote-survey");
        votableDownvoteSurveyController.displayDownvoteSurvey(event);
      } else if (isDownvote) {
        const voteId = await this.processVote(DOWNVOTE, UPVOTE);
        return { id: voteId };
      }
    } catch (error) {
      console.log("Error processing vote:", error);
    }
  }

  async processVote(currentVote, oppositeVote) {
    let voteId;
    switch (this.lastVote) {
      case currentVote:
        await this.destroyVote(currentVote);
        break;
      case oppositeVote:
        await this.destroyVote(oppositeVote);
        voteId = await this.createVote(currentVote);
        break;
      case "":
        voteId = await this.createVote(currentVote);
        break;
    }
    return voteId;
  }

  async createVote(vote) {
    const data = new URLSearchParams({
      "vote": vote,
      "item_id": this.itemId,
      "token": this.token
    }).toString();

    try {
      const response = await this.handleVoteRequest("POST", this.votesPath, data);
      this.setLastVote(vote);
      this.dispatchVote(vote);
      return response.data.id;
    } catch (error) {
      console.error(`Could not create vote for item ${this.itemId}:`, error);
    }
  }

  async destroyVote(vote) {
    const data = new URLSearchParams({
      "vote": vote,
      "item_id": this.itemId,
      "token": this.token
    }).toString();

    try {
      await this.handleVoteRequest("DELETE", this.votePath, data);
      this.setLastVote("");
      this.dispatchVote("removeVote");
    } catch (error) {
      console.error(`Could not destroy vote for item ${this.itemId}:`, error);
    }
  }

  handleVoteRequest(method, url, data) {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        type: method,
        url: url,
        data: data,
        success: (data, status, xhr) => resolve({data, status, xhr}),
        error: reject
      });
    });
  }

  setLastVote(vote) {
    this.lastVote = vote;
  }

  dispatchVote(message) {
    const event = new CustomEvent("vote", { detail: { message } });
    this.element.dispatchEvent(event);
  }
}
