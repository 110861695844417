import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["output"];

  setInviteCodeText(event) {
    this.outputTarget.textContent = "Generate invitation code";
  }

  setEmailInviteText(event) {
    this.outputTarget.textContent = "Complete and send invitations now";
  }

  toggleButtonText(event) {
    if (event.target.checked) {
      this.outputTarget.textContent = "Add recipients and send invitations now";
    } else {
      this.outputTarget.textContent = "Add recipients";
    }
  }
}
