import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ['button', 'panel'];
  }

  clickOutsidePanel(event) {
    if ( (this.buttonTarget || this.panelTarget) === event.target || ( this.buttonTarget.contains(event.target) || this.panelTarget.contains(event.target) ) ) {
      return;
    } else {
      this.closePanel();
    }
  }

  closePanel() {
    this.buttonTarget.classList.remove("open");
    this.panelTarget.classList.remove("open")
  }

  keyPress(event) {
    if (event.code == 'Escape' && document.getElementById('item-info-panel-drawer').classList.contains('open')) {
      this.closePanel();
    }
  }

  togglePanel(event) {
    event.preventDefault();
    this.buttonTarget.classList.toggle("open");
    this.panelTarget.classList.toggle("open");
  }
}
