import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["modal", "modalContainer"];
  }

  toggleModal(event) {
    event.preventDefault();
    this.modalTarget.classList.toggle("is-active");
  }

  keyPress(event) {
    if (event.code == "Escape" && this.modalTarget.classList.contains("is-active")) {
      this.toggleModal(event);
    }
  }

  async showModal(event) {
    const route = event.target.closest("[data-route]").dataset.route;
    const awaitResult = await fetch(`${route}`).then(function (response) {
      return response.text();
    });
    this.modalContainerTarget.innerHTML = awaitResult;
    this.modalTarget.classList.add("is-active");
  }
}
