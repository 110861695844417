import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  initialize() {
    this.loadNextItem(1000);
  }

  loadNextItem(delay) {
    if (this.data.get("learnerResponded") == "true") {
      setTimeout(() => {
        Rails.ajax({
          url: this.data.get("selectItemPath"),
          type: 'post'
        });
      }, delay);
    }
  }
}
