import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["fileUploader", "filename", "fileInput", "uploadButton"];
  }

  displayFilenameAndUploadButton() {
    this.fileUploaderTarget.classList.add("has-name");
    this.filenameTarget.classList.remove("is-hidden");
    if (this.hasUploadButtonTarget) {
      this.uploadButtonTarget.classList.remove("is-hidden");
    }
    this.filenameTarget.innerHTML = this.fileInputTarget.files[0].name
  }
}
