import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static get targets() {
        return ["newPasswordField", "passwordField"];
    }

    enablePasswordField() {
      if (this.newPasswordFieldTarget.value.length == 0) {
        this.passwordFieldTarget.value = "";
        this.passwordFieldTarget.required = false;
        this.passwordFieldTarget.disabled = true;
      } else {
        this.passwordFieldTarget.required = true;
        this.passwordFieldTarget.disabled = false;
      }
    }
}
