import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["gradedLiveRegion"];
  }

  updateGradedLiveRegion() {
    this.gradedLiveRegionTarget.textContent = this.data.get("gradedStatus");
  }
}
