import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "predefinedDomainForm",
    "emailUserField",
    "emailDomainField",
    "emailField"
  ]

  showEmailField(event) {
    event.preventDefault();

    this.predefinedDomainFormTarget.classList.add("is-hidden");
    this.emailUserFieldTarget.setAttribute("disabled", true);
    this.emailDomainFieldTarget.setAttribute("disabled", true);
    this.emailFieldTarget.classList.remove("is-hidden");
    this.emailFieldTarget.removeAttribute("disabled");
  }
}
